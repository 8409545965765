import { BackendApi, PaginatedWttRestResponseBody, WttRestResponseBody } from '../backend-api';
import { isApiResponse } from '../api';
import { PresenterSchemaDefinition } from './presenter-schema-definition';
import { SchemaDefinitionResponse } from './schema-definition-response';
import { ListDataResponse, PaginatedDataResponse, SingleDataResponse } from './table-data-response';


export class TableApi extends BackendApi {
  async getSchemaDefinition(className: string): Promise<SchemaDefinitionResponse> {
    const response = await this.mapGeneralErrors<SchemaDefinitionResponse>(
      this.fetch('OPTIONS', `/api/v1/config/universal/schema/${ className }`),
    );

    if (isApiResponse(response)) {
      return response;
    }

    const responseBody = await response.json() as WttRestResponseBody<PresenterSchemaDefinition>;
    const data = responseBody.data;

    return {
      status: 'OK',
      schemaName: data.schemaName,
      schemaDefinition: data.schemaDefinition,
    };
  }

  async getPaginatedData(
    className: string,
    pageNo: number, pageSize: number, sortBy?: string, sortDir?: 'asc' | 'desc',
  ): Promise<PaginatedDataResponse<any>> {
    const response = await this.mapGeneralErrors<PaginatedDataResponse<any>>(
      this.fetch('GET', `/api/v1/config/universal/page/${ className }`,
        {
          queryParams: {
            className,
            pageNo,
            pageSize,
            sortBy,
            sortDir,
          },
        }),
    );

    if (isApiResponse(response)) {
      return response;
    }

    const responseBody = await response.json() as PaginatedWttRestResponseBody<any>;
    const data = responseBody.data;

    return {
      status: 'OK',
      data,
      page: responseBody.page,
      total: responseBody.total,
      length: responseBody.length,
      totalPages: responseBody.totalPages,
      isLast: responseBody.isLast,
    };
  }

  async getData(
    className: string,
  ): Promise<ListDataResponse<any>> {
    const response = await this.mapGeneralErrors<ListDataResponse<any>>(
      this.fetch('GET', `/api/v1/config/universal/data/${ className }`),
    );

    if (isApiResponse(response)) {
      return response;
    }

    const responseBody = await response.json() as WttRestResponseBody<any>;
    return {
      status: 'OK',
      data: responseBody.data
    };
  }

  async updateData(
    className: string,
    dataId: number,
    data: any
  ): Promise<SingleDataResponse<any>> {
    const response = await this.mapGeneralErrors<SingleDataResponse<any>>(
      this.fetch('POST', `/api/v1/config/universal/save/${ className }`, {
        queryParams: {
          id: dataId
        },
        body: data
      }),
    );

    if (isApiResponse(response)) {
      return response;
    }

    const responseBody = await response.json() as WttRestResponseBody<any>;
    return {
      status: 'OK',
      data: responseBody.data
    };
  }
}

export const tableApi = new TableApi();
