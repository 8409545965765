import { ILoadingCellRendererComp } from 'ag-grid-community';

export class WttLoadingCellRenderer implements ILoadingCellRendererComp {
  eGui!: HTMLElement;

  init() {
    this.eGui = document.createElement('div', {});
    this.eGui.classList.add('spinner');
  }

  getGui() {
    return this.eGui;
  }
}
