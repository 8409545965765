import {
  formatDate,
  formatDateTime,
  formatDateUTC, formatPublicationDateEtka,
  formatTimestampUTC,
  translate,
} from '../../../apis/localization/localization-service';
import { ITooltipParams, ValueFormatterFunc } from 'ag-grid-community';

export const ENUM_VALUE_FORMATTER: ValueFormatterFunc = (params) =>
  params.value?.toUpperCase();

export const DATE_VALUE_FORMATTER: ValueFormatterFunc = (params) =>
  params.value ? formatDate(params.value) : '';

export const PUBLICATION_DATE_ETKA_VALUE_FORMATTER: ValueFormatterFunc = (params) =>
  params.value ? formatPublicationDateEtka(params.value) : '';

export const DATE_UTC_VALUE_FORMATTER: ValueFormatterFunc = (params) =>
  params.value ? formatDateUTC(params.value) : '';

export const DATE_TIME_VALUE_FORMATTER: ValueFormatterFunc = (params) =>
  params.value ? formatDateTime(params.value) : '';

export const FORMATTED_VALUE_TOOLTIP_GETTER = (params: ITooltipParams) =>
  params.valueFormatted

export const STRING_TOOLTIP_VALUE_GETTER = (params: ITooltipParams) =>
  params.valueFormatted || ['string', 'number', 'boolean'].includes(typeof params.value) ? params.value : undefined;

export const TIMESTAMP_UTC_TOOLTIP_VALUE_GETTER = (params: ITooltipParams) =>
  params.value ? formatTimestampUTC(params.value) : '';

export const EAY_CODE_TOOLTIP_VALUE_GETTER = (params: ITooltipParams) =>
  // take the colid ("eCode", "aCode", "yCode"), append the value and translate
  params.value ? translate(params.column?.getUniqueId() + '_' + params.value) : '';

