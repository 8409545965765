import { ColDef, IGetRowsParams } from 'ag-grid-community';
import { tableApi } from '../../../../apis/table/table-api';
import { TableProvider } from '../wtt-table';
import { translate } from '../../../../apis/localization/localization-service';
import { PresenterField, PresenterTable } from '../../../../apis/table/presenter-schema-definition';
import { WttDropDownCellEditor } from '../cell-editors/wtt-drop-down-cell-editor';
import { LOADING_CELL_RENDERER_SELECTOR } from '../cell-renderer/cell-renderer-selectors';
import {
  DATE_VALUE_FORMATTER,
  ENUM_VALUE_FORMATTER,
  FORMATTED_VALUE_TOOLTIP_GETTER,
  STRING_TOOLTIP_VALUE_GETTER,
} from '../value-formatters';
import { PartModelRowStateRenderer } from '../cell-renderer/part-model-row-state-renderer';
import { handleDefaultApiErrors } from '../../../../apis/api';
import _ from 'lodash';

export type ClassColDefOverrides = DeepReadonly<Record<string, (field: PresenterField) => ColDef>>

export const CLASS_COL_DEF_OVERRIDES: ClassColDefOverrides = {
  'de.lexcom.wtt.backend.valuedriver.categories.model.SubHpgModel': () => ({
    valueFormatter: (params) => params.value?.subHpg,
  }),
  'de.lexcom.wtt.backend.masterdata.model.PartModel$RowState': () => ({
    cellRenderer: PartModelRowStateRenderer,
    cellClass: 'icon-cell',
    tooltipValueGetter: (params) => params.value
      ? translate('PartsData.ROWSTATE.' + params.value)
      : undefined,
  }),
};

export type ColDefWithIndex = ColDef & { colIndexOverride?: number }

export class UniversalControllerTableProvider implements TableProvider {

  constructor(
    private className: string,
    private colDefOverrides: Record<string, Partial<ColDefWithIndex>> = {},
    private _tableApi = tableApi,
  ) {
  }

  public readonly columnTypes = {
    'ALPHA': {},
    'NUMERIC': {},
    'BOOL': {},
    'CLASS': {},
    'ENUM': {},
    'DATE': {},
  };

  async getColumnDefinitions(): Promise<ColDef[]> {

    const schemaDefinitionResponse = await handleDefaultApiErrors(
      this._tableApi.getSchemaDefinition(this.className),
    );

    if (schemaDefinitionResponse.status === 'RESOLVED_ERROR') {
      return [];
    }

    return _.chain(schemaDefinitionResponse.schemaDefinition.fields)
      .filter(field => field.visibility === 'VISIBLE')
      .map((field) => this.createColDefFromPresenterField(
        field, schemaDefinitionResponse.schemaDefinition.table,
      ))
      .orderBy(field => field.colIndexOverride ?? Infinity)
      .value();
  }

  private createColDefFromPresenterField(field: PresenterField, table: PresenterTable): ColDefWithIndex {
    return {
      colId: field.field,
      field: field.field,
      headerName: this.getHeaderName(field, table),
      editable: field.editable,
      sortable: true,
      type: field.type,
      cellRendererSelector: LOADING_CELL_RENDERER_SELECTOR,
      tooltipValueGetter: STRING_TOOLTIP_VALUE_GETTER,
      ...this.getTypeSpecificColDefOverrides(field),
      ...this.colDefOverrides['*'],
      ...this.colDefOverrides[field.name],
    };
  }

  protected getHeaderName(field: PresenterField, table: PresenterTable): string {
    return translate(`${ table.name }.${ field.name }`);
  }

  private getTypeSpecificColDefOverrides(field: PresenterField): ColDef {
    switch (field.type) {
      case 'ALPHA':
        return {
          cellDataType: 'text',
        };
      case 'NUMERIC':
        return {
          cellDataType: 'number',
        };
      case 'BOOL':
        return {
          cellDataType: 'boolean',
        };
      case 'CLASS':
        return {
          ...this.getClassSpecificColDefOverrides(field),
        };
      case 'ENUM':
        return {
          cellDataType: 'text',
          valueFormatter: ENUM_VALUE_FORMATTER,
          cellEditor: WttDropDownCellEditor,
          cellEditorParams: {
            values: field.values || [],
          },
          ...this.getClassSpecificColDefOverrides(field),
        };
      case 'DATE':
        return {
          cellDataType: 'date',
          valueFormatter: DATE_VALUE_FORMATTER,
          tooltipValueGetter: FORMATTED_VALUE_TOOLTIP_GETTER,
          cellEditor: 'agDateStringCellEditor',
        };
      default:
        return {};
    }
  }

  private getClassSpecificColDefOverrides(field: PresenterField): ColDef | undefined {
    if (field.class && CLASS_COL_DEF_OVERRIDES[field.class]) {
      return CLASS_COL_DEF_OVERRIDES[field.class](field);
    } else {
      return undefined;
    }
  }

  getRows(params: IGetRowsParams): void {
    const pageSize = params.endRow - params.startRow;
    const pageNo = Math.floor(params.startRow / pageSize);

    this._tableApi
      .getPaginatedData(
        this.className,
        pageNo,
        pageSize,
        params.sortModel[0]?.colId,
        params.sortModel[0]?.sort,
      )
      .then(handleDefaultApiErrors)
      .then(response => {
        if (response.status === 'OK') {
          params.successCallback(response.data, response.total);
        } else {
          params.failCallback();
        }
      });
  }

}
