import { ICellEditorComp, ICellEditorParams } from 'ag-grid-community';

export class WttDropDownCellEditor implements ICellEditorComp {
  value!: string;
  selectElement!: HTMLWttSelectElement;

  init(params: ICellEditorParams & { values: () => Promise<string[]> }) {
    this.value = params.value;
    this.selectElement = document.createElement('wtt-select');
    params.values()
      .then(v => v.map(value => ({
        value, translationKey: {
          key: value,
        },
      })))
      .then(values => this.selectElement.items = values);
    this.selectElement.value = params.value;
    this.selectElement.addEventListener('itemSelect', (event) => {
      this.value = event.detail.newVal.value;
    });
    this.selectElement.cssClass = 'wtt-table-select';
  }

  getGui() {
    return this.selectElement;
  }

  afterGuiAttached() {
    this.selectElement.focus();
  }

  getValue() {
    return this.value;
  }

  isPopup() {
    return false;
  }
}
