import { CellRendererSelectorFunc } from 'ag-grid-community';
import { WttLoadingCellRenderer } from './wtt-loading-cell-renderer';

export const LOADING_CELL_RENDERER_SELECTOR: CellRendererSelectorFunc = params => {
  if (params.api.getColumnState().find(column => !column.hide)?.colId !== params.column?.getColId()) {
    return undefined;
  }

  return params.data === undefined
    ? { component: WttLoadingCellRenderer }
    : undefined;
};
